<template>

	<div class="modal fade" id="share" tabindex="-1" aria-labelledby="shareLabel" aria-hidden="true">

		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="shareLabel">Share portal</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body p-0">

					<div class="accordion border-0 rounded-0 border-top-0" id="share-accordion">
						<div class="accordion-item border-top-0">
							<h2 class="accordion-header" id="generate-link">
								<button class="accordion-button rounded-0 bg-white text-primary p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
									<span class="material-icons-outlined me-2">share</span>
									Generate share link
								</button>
							</h2>
							<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="generate-link" data-bs-parent="#share-accordion">
								<div class="accordion-body p-3">
									<form @submit.prevent="generate">
										<!-- <p class="alert alert-info small">
											Generating a share link allows stakeholders who are not part of your team to preview the project and add comments.
										</p> -->
										<div v-if="!share_link" @submit.prevent="generate">
											<form_control
												label="Stakeholders email"
												type="text"
												placeholder="eg: john@example.com"
												help="Enter the email address of the stakeholder you wish to share with"
												name="share_email"
												:required="true"
												:error="error"
												v-model="share_email"
											/>

											<btn_submit 
												v-if="!share_link"
												label="Generate link" 
												:icon_after="true" 
												:loading="generate_loading" 
											/>
										</div>

										<div v-else>
											<p class="fw-bold mb-2">Share link for {{ share_email }}</p>
											<div class="p-3 bg-light border rounded mb-3">
												<code>
													{{ share_link }}
												</code>
											</div>
											<btn_submit
												label="Generate another link" 
												:icon_after="true"
												v-on:click="share_link = null"
											/>							
										</div>
									</form>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="customise-settings">
								<button class="accordion-button rounded-0 collapsed bg-white text-primary p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
									<span class="material-icons-outlined me-2">tune</span>
									Customise share portal
								</button>
							</h2>
							<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="customise-settings" data-bs-parent="#share-accordion">
								<div class="accordion-body p-3">
									
									<p v-if="success" class="alert alert-success d-flex align-items-center">
										<span class="material-icons-outlined me-2">check_circle</span>
										The {{ tab }} details were updated successfully
									</p>

									<p v-if="error.message" class="alert alert-danger">
										{{ error.message }}
									</p>

									<form @submit.prevent="save">
										
										<div v-if="value && value.settings">

											<label
											v-for="row in sharing"
											:for="'exclude-' + row.key" 
											class="py-2 d-flex align-items-center"
											:key="'sharing-' + row.key"
											>
												<div class="form-check form-switch me-1">
													<input 
													:name="'exclude-' + row.key" 
													class="form-check-input" 
													type="checkbox" 
													role="switch" 
													:id="'exclude-' + row.key" 
													v-model="value.settings['exclude-' + row.key]"
													>
												</div>
												<span>
													<p class="mb-0">{{ row.name }}</p>
												</span>
											</label>


											<p class="form-text">All sections will be visible by default. Check the sections you DO NOT want to be visible on the share portal.</p>

											<btn_submit
												label="Save settings" 
												:icon_after="true" 
												:loading="loading" 
											/>

										</div>

									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import site_service from '@/services/site_service'

export default {
	name: 'components.share_modal',
	props: {
		modelValue: Object,
		is_plan: Boolean
	},
	data() {
		return {
			error: {},
			success: false,
			generate_loading: false,
			loading: false,
			share_email: null,
			share_link: null,
			sharing: [
				{
					key: 'brief',
					name: 'Exclude brief section'
				},
				{
					key: 'sitemap',
					name: 'Exclude sitemap section'
				},
				{
					key: 'content',
					name: 'Exclude content section'
				},
				{
					key: 'design',
					name: 'Exclude design section'
				},
				{
					key: 'build',
					name: 'Exclude build section'
				},
			],
		}
	},
	computed: {

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)	
			}
		},
	},
	components: {
		form_control,
		btn_submit,
	},
	methods: {
		reset_error()
		{
			this.error = {}
		},

		async generate()
		{
			this.generate_loading = true 

			site_service.generate_share( this.$route.params.workspace_id, this.$route.params.site_id, {share_email: this.share_email, is_plan: this.is_plan} ).then(( response ) => {

				this.share_link = response.data

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.generate_loading = false

			})
		},

		async save()
		{
			this.loading = true 

			this.reset_error()

			site_service.update_wizard( this.$route.params.workspace_id, this.$route.params.site_id, this.value ).then(( response ) => {
				this.value = response.data
				this.$emit('update:modelValue', this.value)
				this.success = true
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	}
}
</script>

<style>

</style>
